/* eslint-disable camelcase */

import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { NOOP } from '../../../components/utils'
import InfoIconSvg from '../../../images/info-sign.svg'

/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const {
    openModal = true,
    setOpenModal = NOOP
  } = props

  // functions
  const onHide = () => {
    setOpenModal(false)
  }

  return <>

        <Modal show={openModal} onHide={onHide}>
            <Modal.Body>
                <h5 className="m-0 mb-3"><img src={InfoIconSvg} className="mr-3" />Tender has ended</h5>

                <p>Bids are in review.</p>

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Close</Button>
                </div>

            </Modal.Body>
        </Modal>

      </>
}

export default Page
